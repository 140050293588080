<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-button
            :router-link="{
              name: backPrevRouteName,
              params: { dontTrackCameFrom: true },
            }"
            router-direction="back"
          >
            <ion-icon :ios="backOutline" :md="backSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ accountName }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <!-- เลือกช่วงวันที่แสดง -->
      <ion-grid>
        <ion-row>
          <ion-col>
            <small>ตั้งแต่</small><br />
            <ion-item lines="none" color="light">
              <ion-datetime
                display-format="YYYY-MM-DD"
                v-model="from"
                @ionChange="fetchTransactions"
              ></ion-datetime>
            </ion-item>
          </ion-col>
          <ion-col>
            <small>ถึง</small><br />
            <ion-item lines="none" color="light">
              <ion-datetime
                display-format="YYYY-MM-DD"
                v-model="to"
                @ionChange="fetchTransactions"
              ></ion-datetime>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- แสดงรายการ -->
      <transaction-list
        :start-balance="start_balance"
        :transactions="transactions"
        :show-datetime="true"
        :range-from="from.substr(0, 10)"
        :range-to="to.substr(0, 10)"
        :loading-transactions="loadingTransactions"
        @change="fetchTransactions"
      ></transaction-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { useStore } from "vuex";
import { computed, onMounted, ref, toRef } from "vue";
import TransactionList from "../../components/TransactionList.vue";
import { chevronBackOutline, chevronBackSharp } from "ionicons/icons";

export default {
  name: "Transactions",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonIcon,
    IonButton,
    IonButtons,
    IonMenuButton,
    IonDatetime,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    TransactionList,
  },
  setup(props) {
    const store = useStore();

    const id = toRef(props, "id");

    const today = new Date();
    const from = ref(
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toISOString().substr(0, 10)
    );
    const to = ref(today.toISOString().substr(0, 10));
    const start_balance = ref(null);
    const transactions = ref([]);
    const loadingTransactions = ref(false);

    const fetchTransactions = async () => {
      loadingTransactions.value = true;
      const res = await window.axios.get(
        `/api/saves/${store.state.save.current_save_id}/accounts/${id.value}/transactions` +
          "?f=" +
          from.value.substr(0, 10) +
          "&t=" +
          to.value.substr(0, 10)
      );

      start_balance.value = res.data.start_balance;
      transactions.value = [...res.data.transactions];
      loadingTransactions.value = false;
    };

    onMounted(() => fetchTransactions());

    return {
      from,
      to,
      start_balance,
      transactions,
      loadingTransactions,
      // methods
      fetchTransactions,
      // computed
      accountName: computed(() => {
        return store.getters["account/hashedAccounts"][id.value].account_name;
      }),
      backPrevRouteName: computed(() => {
        return store.getters["account/hashedCategories"][id.value]
          ? "categories"
          : "assets";
      }),
      // icons
      backSharp: chevronBackSharp,
      backOutline: chevronBackOutline,
    };
  },
};
</script>

<style scoped></style>
